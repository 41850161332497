import { useRouter } from 'next/router';
import { LocalizationProvider } from 'ilenia';
import { ThemeProvider } from 'styled-components';

import { SeoHead } from '@bscom/seo';
import { getLocaleByHost } from '@bscom/locales';
import { projectTheme } from '@bscom/styling/old-brand-theme';

import { availableLanguageLocales } from 'src/utilities/locales';

function MyApp({ Component, pageProps = {}, locale, translations }) {
  const { code: localeCode, subdomain } = locale;

  const router = useRouter();

  return (
    <ThemeProvider theme={projectTheme}>
      <LocalizationProvider locale={localeCode} translations={translations}>
        <>
          <SeoHead
            locales={availableLanguageLocales}
            localeCode={localeCode}
            subdomain={subdomain}
            createSubdomainUrl={(subdomain) =>
              `https://${subdomain ? `${subdomain}.` : ''}legal.trustpilot.com${
                router.asPath.split('?')[0] || ''
              }`
            }
          />
          <Component {...pageProps} />
        </>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  const host = (() => {
    if (typeof window !== 'undefined') {
      return window.location.host;
    }

    if (ctx?.req?.headers?.host) {
      return ctx.req.headers.host;
    }

    return 'legal.trustpilot.com';
  })();

  const locale = getLocaleByHost(host);

  const enLibTranslations = await import(
    `@trustpilot/businesssite-components/localisation/en/strings.json`
  );

  const enTranslations = await import('../localisation/en-US/strings.json');

  let translations = {
    ...enLibTranslations,
    ...enTranslations,
  };

  try {
    const localisedLibTranslations = await import(
      `@trustpilot/businesssite-components/localisation/${locale.code}/strings.json`
    );

    const localeTranslations = await import(
      `../localisation/${locale.code}/strings.json`
    );

    translations = {
      ...translations,
      ...localisedLibTranslations,
      ...localeTranslations,
    };
  } catch (error) {}
  return {
    locale,
    translations,
  };
};

export default MyApp;
